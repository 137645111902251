import { useContext } from 'react'
import { CallContext } from './currentCallContext'

const useCallContext = () => {
    const context = useContext(CallContext)
    if (!context) {
        throw new Error(
            'useCallContext must be used within a NotificationsDashboardContext'
        )
    }
    return context
}
useCallContext.displayName = 'useCallContext'

export { useCallContext }
