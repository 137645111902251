export const MedicalDeviceCardLoader = () => {
  return (
    <div className='animate-pulse'>
      <div className="space-y-3">
        <div className="grid grid-cols-3 gap-4">
          <div className="h-4 bg-slate-200 rounded col-span-2"></div>
          <div className="h-4 bg-slate-200 rounded col-span-1"></div>
        </div>
        <div className="h-10 bg-slate-200 rounded"></div>
      </div>
    </div>
  )
}