import { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import { routes } from 'routes/routing'
import {
  NotificationType,
  useMarkNotificationAsRead,
} from 'features/Notifications/services'

import { useNotificationContext } from 'features/Notifications/hooks'

import Accordion from '../Accordion'
import {
  getHeaderDate,
  getFooterDate,
  getLastname,
  getName,
  getAccordionTitle,
} from '../utils'

import {
  NotificationCardActionsProps,
  NotificationCardHeaderProps,
  NotificationCardProps,
  NotificationGroupProps,
  ReadNotificationGroupProps,
  ReadNotificationCardProps,
  ReadNotificationCardHeaderProps,
  ReadNotificationCardFooterProps,
} from './types'

import IconCall from 'assets/icons/icon_call.svg'
import IconMessage from 'assets/icons/icon_message.svg'
import IconClipboard from 'assets/icons/icon_clipboard_B.svg'
import IconActivity from 'assets/icons/icon_activity.svg'
import IconCallAlert from 'assets/icons/icon_alert_call.svg'
import IconMedicamentAlert from 'assets/icons/icon_medication.svg'
import IconDeviceAlert from 'assets/icons/icon_alert_device.svg'
import ReportDevice from '../ReportDeviceNotifications'
import IconHealth from 'assets/icons/icon_health_white.svg'

const notificationVariants: Record<NotificationType, any> = {
  call: {
    iconName: IconCall,
    labels: { action: 'Llamar', readByPreffix: 'Atendió la llamada' },
    bg: 'bg-[#48B16E]',
    color: 'text-[#48B16E]',
    titleType: 'Llamadas',
    iconAlert: IconCallAlert,
  },
  medication: {
    iconName: IconMessage,
    labels: { action: 'Responder', readByPreffix: 'Atendido por' },
    bg: 'bg-[#00B1CC]',
    color: 'text-[#00B1CC]',
    titleType: 'Medicamentos',
    iconAlert: IconMedicamentAlert,
  },
  report: {
    iconName: IconActivity,
    labels: { action: 'Ver detalle', readByPreffix: 'Revisado por' },
    bg: 'bg-[#407FE7]',
    color: 'text-[#407FE7]',
    titleType: 'Dispositivos',
    iconAlert: IconDeviceAlert,
  },
  prescription: {
    iconName: IconHealth,
    labels: { action: 'Ver', readByPreffix: 'Atendido por' },
    bg: 'bg-[#00B1CC]',
    color: 'text-[#00B1CC]',
    titleType: 'Medicamentos',
    iconAlert: IconHealth,
  },
  recommendation: {
    iconName: IconMessage,
    labels: { action: 'Ver', readByPreffix: 'Revisado por' },
    bg: 'bg-[#407FE7]',
    color: 'text-[#407FE7]',
    titleType: 'Dispositivos',
    iconAlert: IconMessage,
  },
  consultation: {
    iconName: IconClipboard,
    labels: { action: 'Ir', readByPreffix: 'Revisado por' },
    bg: 'bg-[#528EB7]',
    color: 'text-[#407FE7]',
    titleType: 'Dispositivos',
    iconAlert: IconClipboard,
  },
  queryWorkplan: {
    iconName: IconClipboard,
    labels: { action: 'Ir', readByPreffix: 'Revisado por' },
    bg: 'bg-[#528EB7]',
    color: 'text-[#407FE7]',
    titleType: 'Dispositivos',
    iconAlert: IconClipboard,
  },
  annotations: {
    iconName: IconMessage,
    labels: { action: 'Ver', readByPreffix: 'Revisado por' },
    bg: 'bg-[#407FE7]',
    color: 'text-[#407FE7]',
    titleType: 'Dispositivos',
    iconAlert: IconMessage,
  },
  attended: {
    iconName: IconMessage,
    labels: { action: 'Ver', readByPreffix: 'Revisado por' },
    bg: 'bg-[#407FE7]',
    color: 'text-[#407FE7]',
    titleType: 'Dispositivos',
    iconAlert: IconMessage,
  },
  ['patient-events']: {
    iconName: IconClipboard,
    labels: { action: 'Ir', readByPreffix: 'Revisado por' },
    bg: 'bg-[#528EB7]',
    color: 'text-[#407FE7]',
    titleType: 'Dispositivos',
    iconAlert: IconClipboard,
  }
}

export const NotificationCardActions: FC<NotificationCardActionsProps> = ({
  variant,
  onClickAction,
}) => {
  const selectedVariant = notificationVariants[variant]
  return (
    <div>
      <Button
        className={`p-2.5 w-[135px] ${selectedVariant?.bg}`}
        onClick={onClickAction}
      >
        <span className="flex flex-row items-center gap-2">
          <img
            src={selectedVariant?.iconName}
            alt={selectedVariant?.iconName}
            className="sm:w-[16px] sm:h-[16px]"
          />
          <span className={'text-white text-caption'}>
            {selectedVariant?.labels.action}
          </span>
        </span>
      </Button>
    </div>
  )
}

export const NotificationCardDescription: FC = ({ children }) => {
  return <div className="text-body2 text-grey-600">{children}</div>
}

export const NotificationCardHeader: FC<NotificationCardHeaderProps> = ({
  notification,
  alert,
}) => {
  const name = getName(notification.name)
  const lastname = getLastname(notification.name)
  const date = notification.createdAt
    ? getHeaderDate(notification.createdAt)
    : null
  const selectedVariant = notificationVariants[notification.notificationType]
  return (
    <div className={'flex flex-row justify-between items-center w-[100%]'}>
      {alert ? (
        <span className={`flex ${selectedVariant?.color}`}>
          <img
            className="mr-[17px]"
            src={selectedVariant?.iconAlert}
            alt=""
            width={20}
            height={20}
          />
          {selectedVariant?.titleType}
        </span>
      ) : (
        <div className={'flex flex-row items-center gap-2'}>
          <div
            className={
              'rounded-full bg-[#DFF5FF] text-[#008EA3] w-8 h-8 flex justify-center items-center font-semibold'
            }
          >
            {name.charAt(0)}
            {lastname.charAt(0)}
          </div>
          <span className={'text-body2'}>
            {name} {lastname}
          </span>
        </div>
      )}
      <span className={'text-caption text-[#ADADAD]'}>{date}</span>
    </div>
  )
}

export const ReadNotificationCardHeader: FC<
  ReadNotificationCardHeaderProps
> = ({ notification, onClickAction, alert }) => {
  const name = getName(notification.name)
  const lastname = getLastname(notification.name)
  const selectedVariant = notificationVariants[notification.notificationType]
  const date = notification.createdAt
    ? getHeaderDate(notification.createdAt)
    : null

  return (
    <div className={'flex flex-row justify-between items-center w-[100%]'}>
      {alert ? (
        <>
          <span className={`flex ${selectedVariant?.color}`}>
            <img
              className="mr-[17px]"
              src={selectedVariant?.iconAlert}
              alt=""
              width={20}
              height={20}
            />
            {selectedVariant?.titleType}
          </span>
          <span className={'text-caption text-[#ADADAD]'}>{date}</span>
        </>
      ) : (
        <div className={'flex flex-row items-center gap-2'}>
          <div
            className={
              'rounded-full bg-[#DFF5FF] text-[#008EA3] w-8 h-8 flex justify-center items-center font-semibold'
            }
          >
            {name.charAt(0)}
            {lastname.charAt(0)}
          </div>
          <span className={'text-body2'}>
            {name} {lastname}
          </span>
        </div>
      )}
      {notification.notificationType === NotificationType.report ? (
        <div>
          <Button
            className={`p-2 w-[135px] h-[32px] rounded-lg ${selectedVariant?.bg}`}
            onClick={onClickAction}
          >
            <span className="flex flex-row items-center gap-2">
              <img
                src={selectedVariant?.iconName}
                alt={selectedVariant?.iconName}
                className="sm:w-[12px] sm:h-[12px]"
              />
              <span className={'text-white text-caption'}>
                {selectedVariant?.labels.action}
              </span>
            </span>
          </Button>
        </div>
      ) : null}
    </div>
  )
}

export const ReadNotificationCardFooter: FC<
  ReadNotificationCardFooterProps
> = ({ notification }) => {
  const getFooterContent = () => {
    const selectedVariant = notificationVariants[notification.notificationType]
    const date = getFooterDate(notification.readAt)
    return `${selectedVariant?.labels.readByPreffix} ${notification.attendedName} el ${date}`
  }
  return (
    <div className={'flex flex-row justify-between items-center w-[100%]'}>
      <span className={'text-caption text-[#ADADAD]'}>
        {getFooterContent()}
      </span>
    </div>
  )
}

export const NotificationCardContainer: FC = ({ children }) => {
  return (
    <div
      className={
        'basis-[333px] grow-1 shrink-1 flex flex-col gap-4 border-solid border-[1px] border-[rgba(20, 20, 20, 0.15)] rounded-2xl p-4'
      }
    >
      {children}
    </div>
  )
}

export const NotificationCard: FC<NotificationCardProps> = ({
  notification,
  alert,
}) => {
  const history = useHistory()
  const [openDeviceReport, setOpenReport] = useState(false)

  const { setOpenVideoCall, setSelectedNotification, setOpenDeviceReport } =
    useNotificationContext()

  const onErrorMarkAsRead = () => {
    setSelectedNotification(null)
  }
  const handleCloseDeviceReport = () => {
    setOpenReport(false)
  }
  const onSettledMarkAsRead = () => {
    switch (notification.notificationType) {
      case 'call':
        if (!alert) {
          setOpenVideoCall(true)
        }
        break
      case 'medication':
        history.push(
          `${routes.clinicHistory.url}/patient/${notification.data.body.patientId}/daily-evolution?focusMessage=true`
        )
        break
    }
  }

  const { markNotificationAsRead } = useMarkNotificationAsRead({
    onErrorCb: onErrorMarkAsRead,
    onSettledCb: onSettledMarkAsRead,
  })

  const handleCallNotificationClick = () => {
/*     setSelectedNotification(notification)
 */    markNotificationAsRead(`${notification.notificationId}`)
  }

  const handleDeviceReportNotificationClick = () => {
    /*     setSelectedNotification(notification)
    
     */
    setOpenReport(true)
  }

  const handleMedicationNotificationClick = () => {
    markNotificationAsRead(`${notification.notificationId}`)
  }
  const handleDevicePrescriptionsNotificationClick = () => {
    markNotificationAsRead(`${notification.notificationId}`)
    history.push(routes.patientDashboard.url)
  }
  const handleDeviceRecommendationsNotificationClick = () => {
    markNotificationAsRead(`${notification.notificationId}`)
    history.push(routes.patientDashboard.url)
  }
  const handleDeviceConsultationsNotificationClick = () => {
    markNotificationAsRead(`${notification.notificationId}`)
    history.push(routes.clinicHistory.url + '/patient/' + notification.data.body.patientId + '/workplan')
  }
  const getAction = () => {

    switch (notification.notificationType) {
      case 'call':
        return handleCallNotificationClick
      case 'medication':
        return handleMedicationNotificationClick
      case 'report':
        return handleDeviceReportNotificationClick
      case 'prescription':
        return handleDevicePrescriptionsNotificationClick
      case 'recommendation':
        return handleDeviceRecommendationsNotificationClick
      case
        'consultation':
        return handleDeviceConsultationsNotificationClick
    }
  }

  return (
    <NotificationCardContainer>
      <NotificationCardHeader alert={alert} notification={notification} />
      <NotificationCardDescription>
        {notification.data.title}
      </NotificationCardDescription>
      <NotificationCardActions
        variant={notification.notificationType}
        onClickAction={getAction()}
      />
      {openDeviceReport ? (
        <ReportDevice
        onCloseModal={handleCloseDeviceReport}
        onSubmitForm={()=>{markNotificationAsRead(`${notification.notificationId}`)}}
        deviceSelected={notification.data.body.device}
        />
      ) : null}
    </NotificationCardContainer>
  )
}

export const ReadNotificationCard: FC<ReadNotificationCardProps> = ({
  notification,
  alert,
}) => {
  
  const [openDeviceReport, setOpenDeviceReport] = useState(false)

  const handleCloseDeviceReport = () => {
    setOpenDeviceReport(false)
  }
  const handleDeviceReportNotificationClick = () => {
    setOpenDeviceReport(true)
  }
  const getAction = () => {
    if (notification.notificationType === NotificationType.report) {
      return handleDeviceReportNotificationClick
    }
    return () => { }
  }
  return (
    <NotificationCardContainer>
      <ReadNotificationCardHeader
        alert={alert}
        notification={notification}
        onClickAction={getAction()}
      />
      <NotificationCardDescription>
        {notification.data.title}
      </NotificationCardDescription>
      <ReadNotificationCardFooter notification={notification} />
      {openDeviceReport ? (
        <ReportDevice
        onCloseModal={handleCloseDeviceReport}
        deviceSelected={notification.data.body.device}
        />
      ) : null}
    </NotificationCardContainer>
  )
}

/**
 * @deprecated Use NotificationCardNew instead.
 */
export const NotificationGroup: FC<NotificationGroupProps> = ({
  notificationsGroup,
  defaultOpen = false,
}) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Accordion
      key={notificationsGroup.date}
      title={getAccordionTitle(notificationsGroup.date)}
      defaultOpen={defaultOpen}
    >
      <div className={'transition duration-700 ease-in-out flex flex-row flex-wrap gap-x-2 gap-y-4'}>
        {
          !showMore ?
            (notificationsGroup?.notifications.slice(notificationsGroup?.notifications.length > 10 ? notificationsGroup?.notifications.length - 10 : 0).map((notification) => (
              <NotificationCard
                key={notification.notificationId}
                notification={notification}
              />
            )))
            : (notificationsGroup?.notifications.map((notification) => (
              <NotificationCard
                key={notification.notificationId}
                notification={notification}
              />
            )))

        }


      </div>
      {notificationsGroup?.notifications.length > 10 &&

        <div className="flex mt-10 w-full text-center justify-center	">
          {!showMore ?
            <button className="btn flex text-primary-main cursor-pointer font-semibold" onClick={() => setShowMore(!showMore)}>Ver más</button>
            : <button className="btn flex text-primary-main cursor-pointer font-semibold" onClick={() => setShowMore(!showMore)}>Ver menos</button>
          }
        </div>
      }
    </Accordion>
  )
}

/**
 * @deprecated Use NotificationCardNew instead.
 */
export const ReadNotificationGroup: FC<ReadNotificationGroupProps> = ({
  notificationsGroup,
  defaultOpen = false,
}) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Accordion
      key={notificationsGroup.date}
      title={getAccordionTitle(notificationsGroup.date)}
      defaultOpen={defaultOpen}
    >

      <div className={'transition duration-700 ease-in-out flex flex-row flex-wrap gap-x-2 gap-y-4'}>
        {
          !showMore ?
            (notificationsGroup?.notifications.slice(notificationsGroup?.notifications.length > 10 ? notificationsGroup?.notifications.length - 10 : 0).map((notification) => (
              <ReadNotificationCard
                key={notification.notificationId}
                notification={notification}
              />
            )))
            : (notificationsGroup?.notifications.map((notification) => (
              <ReadNotificationCard
                key={notification.notificationId}
                notification={notification}
              />
            )))

        }


      </div>
      {notificationsGroup?.notifications.length > 10 &&

        <div className="flex mt-10 w-full text-center justify-center	">
          {!showMore ?
            <button className="	 btn flex text-primary-main cursor-pointer font-semibold	" onClick={() => setShowMore(!showMore)}>Ver más</button>
            : <button className="	 btn flex text-primary-main cursor-pointer font-semibold	" onClick={() => setShowMore(!showMore)}>Ver menos</button>
          }
        </div>
      }
    </Accordion>
  )
}

