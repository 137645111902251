import React, { useEffect } from "react";
import IconClose from 'assets/icons/icon_close.svg';
import { useForm } from 'react-hook-form';
import { BehaviorSubject, debounceTime } from 'rxjs';
import Button from "components/Button";
import Input from "components/input";
import IconCopy from 'assets/icons/icon_copy.svg';
import { onCopyClipboard } from "global/utils";

const inputChange = new BehaviorSubject("");
const inputChange$ = inputChange.asObservable();

interface Props {
  showModal: boolean;
  onCloseModal: () => any;
  onSubmitForm: (value: string) => any;
}

const Replace: React.FC<Props> = (props) => {

  const { register, watch } = useForm();

  useEffect(() => {
    const searchSubscription = inputChange$.pipe(debounceTime(500)).subscribe((value) => {
      if (value) onSubmitSearch(value);
    });

    return () => {
      return searchSubscription.unsubscribe();
    }
  }, [])

  const onSubmitSearch = async (value: any) => {
    if (value.length > 0) {
    } else {
    }
  }

  if (props.showModal) {
    return (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative py-8 px-6 w-full max-w-[448px] ">
            <div
              className="border-0 rounded-lg shadow-lg relative w-full  bg-white outline-none focus:outline-none overflow-hidden p-4"
            >
              <div className='border-b-[1px] border-[#14141426] pb-[24px] flex justify-between items-center mb-4'>
                <span className='text-left text-Fs8 font-medium	'>Reemplazar</span>
                <button
                  onClick={() => props.onCloseModal()}
                  className="h-[30px] w-[30px] flex items-center justify-center"
                >
                  <img src={IconClose} alt="close" className='min-w-[1.5rem] min-h-[1.5rem] w-6 h-6' />
                </button>
              </div>

              <p className="text-left text-body1 mb-6">
                Ingrese el código de producto para reemplazar
              </p>

              <Input
                label="Código de producto"
                required
                icon={IconCopy}
                onClickIcon={() => { onCopyClipboard(watch('code')) }}
                value={watch('code')}
                {...register('code')}
              />
              <div className="flex items-center justify-center w-full mt-[24px]" >
                <Button onClick={() => {
                  props.onSubmitForm(watch('code'));
                  props.onCloseModal();
                }} variant='primary' size="large" >Reemplazar</Button>
              </div>

            </div>


          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  } else {
    return <></>
  }
};

export default Replace;
