import { NotificationCardActionsProps, NotificationCardHeaderProps, ReadNotificationCardFooterProps, ReadNotificationCardHeaderProps } from "./types"
import { calculatePotentialNotificationActionRoute, getFooterDate, getHeaderDate, getLastname, getName } from "../utils"
import { variants } from "../NotificationsCounter/NotificationsCounterConst"
import { NotificationType, useMarkNotificationAsRead } from "features/Notifications/services"
import { useHistory, useLocation } from "react-router-dom"
import { useState } from "react"


export const NotificationCardActionRecommendation = ({
  notification
}: {
  notification: NotificationCardActionsProps['notification'],
  selectedGroup?: NotificationType
}) => {
  const history = useHistory();
  const location = useLocation();
  const { markNotificationAsRead } = useMarkNotificationAsRead()
  const [handlingClick, setHandlingClick] = useState(false);

  const handleClickAction = async () => {
    if (handlingClick) return;

    setHandlingClick(true);

    if (!notification?.data?.body?.patientId) {
      console.error("No patient ID provided...", notification);
    }
    const finalRoute = calculatePotentialNotificationActionRoute({
      notification,
      location: location as any
    })
    if (finalRoute && finalRoute !== '/') {
      try {
        markNotificationAsRead(`${notification?.notificationId}`);
        history.push(finalRoute);
      } catch (e) {
        console.error({
          e
        });
      }
    } else {
      console.log("Intent not recognized...", {
        finalRoute
      });
    }
    setHandlingClick(false);
  }

  return (
    <div className='w-fit-content'>
      <div>
        <div
          className={`p-3 ${variants?.queryWorkplan?.active?.bg} rounded-lg min-h-[16px] cursor-pointer inline-block pl-4 pr-4`}
        >
          <span className="flex flex-row items-center gap-2" onClick={handleClickAction}>
            <img
              src={variants?.queryWorkplan?.active?.iconName}
              alt={variants?.queryWorkplan?.active?.iconName}
            />
            <span className={'text-white text-sm text-caption'}>
              Ver Detalle
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}