import IconCopy from 'assets/icons/icon_copy.svg';
import Input from 'components/input';
import { MedicalDeviceServiceModel } from '../hooks/useDevices';
import { useEffect, useState } from 'react';
import { onCopyClipboard } from 'global/utils';

export const MedicalDeviceCardBody = ({
  watch,
  register,
  data
}: {
  watch: any,
  register: any,
  data: MedicalDeviceServiceModel,
}) => {

  const [otp, setOtp] = useState<string>('');
  const [deviceId, setDeviceId] = useState<string>('');

  useEffect(() => {
    if (watch('planDevicePairing.pairing.paired') && watch('planDevicePairing.pairing.pairedWith')) {
      setDeviceId(watch('planDevicePairing.pairing.pairedWith.deviceId') || null)
    }
    setOtp(watch('planDevicePairing.otp.code'))
  }, [watch('planDevicePairing')])

  if (data.devicePairing) {
    return (
      <div className='mt-4'>
        <Input
          label={deviceId ? "Código de producto" : "Código de vinculación"}
          icon={IconCopy}
          onClickIcon={() => { onCopyClipboard(watch('input1')) }}
          value={deviceId || otp || ""}
          {...register('planDevicePairing.otp.code')}
          readOnly={true}
        />
      </div>
    )
  }

  return (
    <div className='mt-4'>
      <Input
        label="Código de producto"
        required
        icon={IconCopy}
        onClickIcon={() => { onCopyClipboard(watch('input1')) }}
        value={watch('serial')}
        {...register('serial')}
        readOnly={!!data.serial}
      />
    </div>
  )
}