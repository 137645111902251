export const PLAN_DEVICE_CREATED = 0;
export const PLAN_DEVICE_ACTIVE = 1;
export const PLAN_DEVICE_REPORTED = 2;
export const PLAN_DEVICE_REPORT_REPLACEMENT = 1;
export const PLAN_DEVICE_REPORT_FAILURE = 2;
export const PLAN_DEVICE_REPORT_REMOVAL = 3;

export enum PAIRING_METHOD {
    ALEXA_VOICE = 'alexa-voice-pairing'
};

export enum OTP_GENERATION_METHOD {
    WEB = "web"
}