import { useContext } from 'react'
import { NotificationsDashboardContext } from '../NotificationsProvider'

const useNotificationDashboardContext = () => {
  const context = useContext(NotificationsDashboardContext)
  if (!context) {
    throw new Error(
      'useNotificationDashboardContext must be used within a NotificationsDashboardContext'
    )
  }
  return context
}
useNotificationDashboardContext.displayName = 'useNotificationDashboardContext'

export { useNotificationDashboardContext }
