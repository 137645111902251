import { NotificationCardActionsProps } from "./types";
import useUser from "features/shared/hooks/useUser";
import { useGetPatientData } from "features/shared/hooks/useGetPatientFullData";
import { useCallContext } from "features/CallCenter/hooks/useCurrentCallContext";
import { useGetAllPlanToday } from "features/PatientDashboard/hooks/userPlanToday";
import { NotificationType, PatientPlanAssignedPersonnel, useMarkNotificationAsRead } from "features/Notifications/services";
import { PatientModel } from "features/Patient/models/Patient.model";
import { useCreateGeneralNotification } from "features/ClinicHistory/hooks/useNotification";
import { ROLE } from "global/constants/roles";
import { EventType } from "global/constants/eventType";
import { variants } from "../NotificationsCounter/NotificationsCounterConst";
import { useState } from "react";

export const NotificationCardActionCall = ({
  notification
}: {
  notification: NotificationCardActionsProps['notification'],
  selectedGroup?: NotificationType
}) => {
  const { mutateAsync: asyncGetPatientData } = useGetPatientData();
  const { userCurrent } = useUser();
  const {
    setCurrentMeeting,
    currentMeeting
  } = useCallContext();
  const { data } = useGetAllPlanToday();
  const planToday = data?.data
  const [handlingClick, setHandlingClick] = useState(false);

  const notificationType = notification?.data?.body?.type || notification?.data?.body?.eventType;
  const { markNotificationAsRead } = useMarkNotificationAsRead()

  const getPatientData = async () => {
    if (!notification?.data?.body?.patientId) {
      return;
    }
    const { data } = await asyncGetPatientData(`${notification?.data?.body?.patientId}`);
    return data as PatientModel;
  }

  const { mutateAsync: createCustomNotification } =
    useCreateGeneralNotification()

  async function handleNotifyPatientOfDoctorCall({
    patientId
  }: {
    patientId: string
  }) {
    try {
      await createCustomNotification({
        json: {
          patientId,
        },
        fromRole: ROLE.HEALTH_PROFESSIONAL,
        toRole: ROLE.PATIENT,
        type: EventType.NOTIFICATION_TYPE_SPECIALIST_CALL
      })
    } catch (e) {
      console.error({
        e
      });
    }
  }

  async function handleNotifyDoctorOfPatientCall({
    patientId
  }: {
    patientId: string
  }) {
    try {
      await createCustomNotification({
        json: {
          patientId,
        },
        fromRole: ROLE.PATIENT,
        toRole: ROLE.HEALTH_PROFESSIONAL,
        type: EventType.NOTIFICATION_TYPE_PATIENT_CALL
      })
    } catch (e) {
      console.error({
        e
      });
    }
  }

  async function handleNotifyAdminOfPatientCall({
    serviceAdminId
  }: {
    serviceAdminId: string
  }) {
    try {
      await createCustomNotification({
        json: {
          serviceAdminId,
        },
        fromRole: ROLE.PATIENT,
        toRole: ROLE.SERVICE_ADMIN,
        type: EventType.NOTIFICATION_TYPE_PATIENT_CALL
      })
    } catch (e) {
      console.error({
        e
      });
    }
  }


  async function handleNotifyPatientOfServiceAdminCall({
    patientId
  }: {
    patientId: string
  }) {
    try {
      await createCustomNotification({
        json: {
          patientId,
        },
        fromRole: ROLE.SERVICE_ADMIN,
        toRole: ROLE.PATIENT,
        type: EventType.NOTIFICATION_TYPE_ADM_SERVICE_CALL
      })
    } catch (e) {
      console.error({
        e
      });
    }
  }

  const handleClickAction = async () => {
    if (handlingClick) return;
    if (!notification) return;
    if (currentMeeting) return;

    setHandlingClick(true);

    try {
      if (userCurrent?.roleSlug === ROLE.PATIENT) {
        // Patient calling Dr.
        if ([EventType.NOTIFICATION_TYPE_DOCTOR_CALL, EventType.NOTIFICATION_TYPE_SPECIALIST_CALL].includes(notificationType || "")) {
          const assignedDoc = (planToday.assignedPersonnel || []).find((element: PatientPlanAssignedPersonnel) => {
            return element.isAttendingDoctor
          })
          if (planToday?.currentService.id && assignedDoc) {
            markNotificationAsRead(`${notification?.notificationId}`)
            await handleNotifyDoctorOfPatientCall({
              patientId: `${userCurrent?.id}`
            })
            setCurrentMeeting({
              planId: planToday?.currentService.id,
              userId: userCurrent.id,
              userProfile: 1,
              initCall: true,
              getIsClose: closeMeeting
            })
          }
        }
        if (notificationType === EventType.NOTIFICATION_TYPE_ADM_SERVICE_CALL) {
          markNotificationAsRead(`${notification?.notificationId}`)
          await handleNotifyAdminOfPatientCall({
            serviceAdminId: `${notification?.data.body?.serviceAdminId}`
          })
          setCurrentMeeting({
            planId: planToday?.currentService.id,
            userId: userCurrent.id,
            userProfile: 1,
            initCall: true,
            getIsClose: closeMeeting
          })
        }
      }
      if (userCurrent?.roleSlug === ROLE.HEALTH_PROFESSIONAL) {  //Health professional
        if ([EventType.NOTIFICATION_TYPE_PATIENT_CALL, EventType.NOTIFICATION_TYPE_DOCTOR_CALL, EventType.NOTIFICATION_TYPE_EMERGENCY_CALL].includes(notificationType || "")) {
          // Patient calling Doctor.
          const patientData = await getPatientData();
          if (patientData) {
            setCurrentMeeting({
              planId: notification.data?.body?.planId,
              userId: userCurrent.id,
              userProfile: 0,
              initCall: true,
              getIsClose: closeMeeting
            })
            markNotificationAsRead(`${notification?.notificationId}`)
            await handleNotifyPatientOfDoctorCall({
              patientId: `${patientData.userId}`
            })
          }
        }
      }
      if (userCurrent?.roleSlug === ROLE.SERVICE_ADMIN) {
        await handleNotifyPatientOfServiceAdminCall({
          patientId: `${notification.data.body.patientId}`
        })
        setCurrentMeeting({
          planId: notification.data.body.planId,
          userId: userCurrent.id,
          userProfile: 0,
          initCall: true,
          getIsClose: closeMeeting
        })
        markNotificationAsRead(`${notification?.notificationId}`)
      }
    } catch (e) {
      console.error({
        e
      });
    }
    setHandlingClick(false);
  }

  const closeMeeting = async () => {
    setCurrentMeeting(null as any)
  }

  if (!notification) {
    return <>You must provide a notification</>
  }

  return (
    <div className='w-fit-content'
      onClick={handleClickAction}>
      <div>
        <div
          className={`p-3 ${variants?.call?.active?.bg} rounded-lg min-h-[16px] cursor-pointer inline-block pl-4 pr-4`}
        >
          <span className="flex flex-row items-center gap-2 pr-4">
            <img
              src={variants?.call?.active?.iconName}
              alt={variants?.call?.active?.iconName}
            />
            <span className={'text-white text-sm text-caption'}>
              Llamar
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}