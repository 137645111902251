import { useState, useEffect, useMemo } from 'react'
import CalendarIcon from 'assets/icons/icon_calendar.svg';
import InputDatePicker from 'components/InputDatePicker';
import Schedule from './Schedule';
import { formatDate } from 'features/shared/utils/text/formatDate';

const mappingDays: {
  [key: string]: string
} = {
  "lunes": "Lun",
  "martes": "Mar",
  "miércoles": "Mir",
  "jueves": "Jue",
  "viernes": "Vie",
  "sábado": "Sab",
  "domingo": "Dom",
}

export const CardDate = (props: {
  initialData?: {
    days: number[],
    month: number,
    year: number
  }
  setData: (data: any) => void
  id: string
  minDate: Date;
  maxDate: Date;
  onCanceled: () => void
  label: string,
  initialStartDateRange?: Date,
  initialEndDateRange?: Date
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startDateRange, setStartDateRange] = useState<Date | undefined>(props.initialStartDateRange);
  const [endDateRange, setEndDateRange] = useState<Date | undefined>(props.initialEndDateRange);
  const onChangeRange = (dates: any) => {
    const [start, end] = dates;
    setStartDateRange(start);
    setEndDateRange(end);
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(false);
    const initialData = props.initialData;
    const {
      days,
      month,
      year,
    } = initialData || {};
    if (initialData) {
      const orderDay = (days || []).sort((a: number, b: number) => { return a - b });
      const startDateRange = new Date(`${month}-${orderDay[0]}-${year}`);
      const endDateRange = new Date(`${month}-${orderDay[(orderDay).length - 1]}-${year}`);
      const arrayDateMultiFormat: any = [];

      orderDay.forEach((day: any) => {
        const dateMulti = new Date(`${month}-${day}-${year}`);
        arrayDateMultiFormat.push(formatDate(dateMulti));
      });
      setStartDateRange(startDateRange);
      setEndDateRange(endDateRange);
    }
  }, []);

  let scheduleLabel = "";
  let scheduleTitle = "";
  const isValidDate = (date: any) => formatDate(date) !== "Invalid Date";
  if (isValidDate(startDateRange) && isValidDate(endDateRange)) {
    if (formatDate(startDateRange) !== formatDate(endDateRange)) {
      scheduleLabel = `${formatDate(startDateRange)} - ${formatDate(endDateRange)}`;
    } else {
      scheduleLabel = formatDate(startDateRange);
    }
  } else {
    scheduleLabel = "Seleccionar";
    scheduleTitle = props.label;
  }

  const shouldDisableApplyButton = (!startDateRange || isNaN(startDateRange?.getTime())) || (!endDateRange || isNaN(endDateRange?.getTime()));

  return (
    <div>
      <div className=" w-full relative  bg-white rounded-xl border-solid border-grey-400">
        <Schedule className={'cursor-pointer'} title={scheduleTitle} description={scheduleLabel} icon={CalendarIcon} onClickIcon={handleClick} />
      </div>
      {isOpen &&
        <div className={`react-datepicker-content rounded-xl bg-white`} style={{
          padding: "1em",
        }}>
          <div className="bg-white  pb-[0]">
            <div style={{
              textAlign: "center",
            }}>
              <div className="text-subtitle1 font-medium mb-[16px]">Seleccionar fecha</div>
            </div>
          </div>

          <InputDatePicker
            id={props.id + 'ppp'}
            selected={startDateRange}
            onChange={onChangeRange}
            startDate={startDateRange}
            endDate={endDateRange}
            selectsRange
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale="es"
            dateFormat="dd/MM/yyyy "
            minDate={props.minDate}
            maxDate={props.maxDate}
            inline={true}
            formatWeekDay={(nameOfDay: string) => {
              return mappingDays[nameOfDay] || nameOfDay;
            }}
          />

          <div style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}>
            <button
              onClick={() => { setIsOpen(false); props.onCanceled(); setStartDateRange(undefined as any); setEndDateRange(undefined as any) }}
              type="button" className=" 
            mt-[16px] min-h-[48px] rounded-[20px] text-button px-[24px] text-black
            disabled:bg-grey-50 disabled:text-grey-200 disabled:border-grey-50
            max-w-[343px] font-semibold"
              style={{
                flex: 1
              }}
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                setIsOpen(false);
                props.setData({ startDateRange, endDateRange });
                setStartDateRange(startDateRange);
                setEndDateRange(endDateRange);
              }}
              type="button" className=" 
              mt-[16px] min-h-[48px] rounded-[20px] text-button px-[24px] bg-primary-dark text-white 
              border-solid border-[1px] border-primary-dark 
              disabled:bg-grey-50 disabled:text-grey-200 disabled:border-grey-50
              max-w-[343px] font-bold"
              style={{
                flex: 1
              }}
              disabled={shouldDisableApplyButton}
            >
              Aplicar
            </button>
          </div>

        </div>}
    </div>
  )
}

export default CardDate
