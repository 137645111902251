import {
  FC,
  Dispatch,
  SetStateAction,
  createContext,
  useState,
  useMemo,
  useEffect,
} from 'react'

import Loader from 'components/Loader'
import SimpleClosingModal from 'components/SimpleClosingModal'
import { MeetingVideoCall } from 'components/MeetingVideoCall'
import LabelForm from 'components/LabelForm'
import Modal from 'components/modal'
import ModalError from 'components/modalError'

import ReportDevice from './components/ReportDeviceNotifications'

import {
  useMarkNotificationAsRead,
  Notification,
  useGetNotifications,
  NotificationType,
  GetNotificationsResponse,
  IUseGetNotificationsData,
  useGetNotificationsSummaryTotal,
  INotificationSummaryData,
} from './services'

import IconVideo from 'assets/icons/icon_video_line.svg'
import { useUpdateDeviceStatus } from 'features/Devices/hooks/useDevice'
import { IFilterData, useNotificationsFiltersDashboard } from './hooks/use-notifications-filters-dashboard'
import { ICardDateProps } from 'features/ClinicHistory/ListPatients/Table/TableUpperActions.utils'
import moment from 'moment'
import useUser from 'features/shared/hooks/useUser'
import { formatDate } from 'features/shared/utils/text/formatDate'
import { InfiniteData } from 'react-query'
import { ROLE } from 'global/constants/roles'
import { ALLOWED_FILTERS } from 'features/ClinicHistory/ListPatients/Table/TableUpperActions'

interface INotificationsContext {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  showModalError: boolean
  setShowModalError: Dispatch<SetStateAction<boolean>>
  textModal: string
  setTextModal: Dispatch<SetStateAction<string>>
  textModalError: string
  setTextModalError: Dispatch<SetStateAction<string>>
  openVideoCall: boolean
  setOpenVideoCall: Dispatch<SetStateAction<boolean>>
  openDeviceReport: boolean
  setOpenDeviceReport: Dispatch<SetStateAction<boolean>>
  selectedNotification: Notification | null
  setSelectedNotification: Dispatch<SetStateAction<Notification | null>>
}

export const NotificationsContext = createContext<INotificationsContext>({
  showModal: false,
  setShowModal: () => { },
  showModalError: false,
  setShowModalError: () => { },
  textModal: 'Registro Exitoso',
  setTextModal: () => { },
  textModalError: 'Ocurrió un error',
  setTextModalError: () => { },
  openVideoCall: false,
  setOpenVideoCall: () => { },
  openDeviceReport: false,
  setOpenDeviceReport: () => { },
  selectedNotification: null,
  setSelectedNotification: () => { },
})

export const NotificationsProvider: FC = ({ children }) => {
  const [showModal, setShowModal] = useState(false)
  const [showModalError, setShowModalError] = useState(false)
  const [textModal, setTextModal] = useState('Registro Exitoso')
  const [textModalError, setTextModalError] = useState('Ocurrió un error')
  const [openVideoCall, setOpenVideoCall] = useState(false)
  const [openDeviceReport, setOpenDeviceReport] = useState(false)
  const [selectedNotification, setSelectedNotification] =
    useState<Notification | null>(null)

  const {
    isLoading: isLoadingUpdateDeviceStatus,
    mutateAsync: updateDeviceStatus,
  } = useUpdateDeviceStatus()

  const { markNotificationAsRead } = useMarkNotificationAsRead()

  const handleCloseVideoCall = () => {
    setOpenVideoCall(false)
    setSelectedNotification(null)
  }

  const handleCloseDeviceReport = () => {
    setOpenDeviceReport(false)
    setSelectedNotification(null)
  }

  const handleSubmitDeviceReport = () => {
    updateDeviceStatus(selectedNotification?.data.body.device?.planDeviceId)
      .then((res) => {
        if (res.data) {
          setShowModal(true)
        } else {
          setShowModalError(true)
          setTextModalError(res)
        }
      })
      .catch((err) => {
        setShowModalError(true)
        setTextModalError(err)
      })
      .finally(() => {
        setOpenDeviceReport(false)
        setSelectedNotification(null)
        markNotificationAsRead(`${selectedNotification?.notificationId}`)
      })
  }

  const noficationsDashboardContext = useMemo(
    () => ({
      showModal,
      setShowModal,
      showModalError,
      setShowModalError,
      textModal,
      setTextModal,
      textModalError,
      setTextModalError,
      openVideoCall,
      setOpenVideoCall,
      openDeviceReport,
      setOpenDeviceReport,
      selectedNotification,
      setSelectedNotification,
    }),
    [
      showModal,
      setShowModal,
      showModalError,
      setShowModalError,
      textModal,
      setTextModal,
      textModalError,
      setTextModalError,
      openVideoCall,
      setOpenVideoCall,
      openDeviceReport,
      setOpenDeviceReport,
      selectedNotification,
      setSelectedNotification,
    ]
  )
  return (
    <NotificationsContext.Provider value={noficationsDashboardContext}>
      {isLoadingUpdateDeviceStatus ? <Loader /> : null}
      {children}
      <>
        {openVideoCall && selectedNotification ? (
          <SimpleClosingModal
            title={
              <LabelForm
                icon={IconVideo}
                label="Videollamada"
                className="mt-4 mb-2 row-span-1 col-span-full"
              />
            }
            onClose={handleCloseVideoCall}
            withDivider={false}
            maxWidth={'80%'}
          >
            <MeetingVideoCall
              planId={selectedNotification.data.body.planId}
              userId={selectedNotification.data.body.patientId}
              showLabel={false}
              initCall={true}
              onLeaveMeeting={handleCloseVideoCall}
            />
          </SimpleClosingModal>
        ) : null}

        {openDeviceReport && selectedNotification ? (
          <ReportDevice
            onCloseModal={handleCloseDeviceReport}
            onSubmitForm={handleSubmitDeviceReport}
            deviceSelected={selectedNotification.data.body.device}
            selectedNotification={selectedNotification}
          />
        ) : null}

        <Modal
          showModal={showModal}
          setShowModal={() => setShowModal(false)}
          text={textModal}
          btn="De acuerdo"
        />
        <ModalError
          text={textModalError}
          showModal={showModalError}
          setShowModal={setShowModalError}
        />
      </>
    </NotificationsContext.Provider>
  )
}



export const ALLOWED_FILTERS_BY_ROLE_BY_TAB = {
  [ROLE.PATIENT]: {
    [NotificationType.call]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE
    ],
    [NotificationType.queryWorkplan]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE
    ],
    [NotificationType.annotations]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE
    ],
    [NotificationType.attended]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE
    ],
    "DEFAULT": [
      ALLOWED_FILTERS.NOTIFICATION_DATE
    ]
  },
  [ROLE.HEALTH_PROFESSIONAL]: {
    [NotificationType.call]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    [NotificationType.queryWorkplan]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    [NotificationType.annotations]: [
      ALLOWED_FILTERS.NOTIFICATION_TYPE,
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    [NotificationType.attended]: [
      ALLOWED_FILTERS.NOTIFICATION_TYPE,
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    "DEFAULT": [
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ]
  },
  [ROLE.SERVICE_ADMIN]: {
    [NotificationType.call]: [
      ALLOWED_FILTERS.NOTIFICATION_TYPE,
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    [NotificationType.queryWorkplan]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    [NotificationType.report]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    [NotificationType.attended]: [
      ALLOWED_FILTERS.NOTIFICATION_TYPE,
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    "DEFAULT": [
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ]
  }
}

export interface INotificationsDashboardContext {
  setFilterData: Dispatch<SetStateAction<IFilterData | undefined>>;
  filterData?: IFilterData;
  queryDateProps?: ICardDateProps;
  getNotificationsResponse?: InfiniteData<GetNotificationsResponse>
  notificationsData: IUseGetNotificationsData
  notificationSummaryData: INotificationSummaryData,
  filtersByTab: ALLOWED_FILTERS[]
}

export const NotificationsDashboardContext = createContext<INotificationsDashboardContext>({
  filterData: undefined,
  setFilterData: () => { },
  notificationsData: undefined as any,
  notificationSummaryData: undefined as any,
  filtersByTab: []
})

export const NotificationDashboardProvider: FC = ({ children }) => {
  const {
    filterData,
    setFilterData
  } = useNotificationsFiltersDashboard()
  const { userCurrent } = useUser()
  const [queryDateProps, setQueryDateProps] = useState<ICardDateProps>();

  useEffect(() => {
    const newParams = new URLSearchParams(location.search);
    const initialDateProps: ICardDateProps = {}
    if (newParams.get("from")) {
      const fromDateMoment = moment(newParams.get("from"), "YYYY-MM-DD");
      if (fromDateMoment.isValid()) {
        initialDateProps.startDateRange = fromDateMoment.toDate();
      }
    }
    if (newParams.get("to")) {
      const toDateMoment = moment(newParams.get("to"), "YYYY-MM-DD");
      if (toDateMoment.isValid()) {
        initialDateProps.endDateRange = toDateMoment.toDate();
      }
    }
    setQueryDateProps(initialDateProps);
  }, []);

  const attendedNotifications = filterData?.notificationType === NotificationType.attended as any;
  const notificationsData: IUseGetNotificationsData = useGetNotifications({
    role: userCurrent?.roleSlug, ...{
      from: filterData?.dateRange?.startDateRange ? formatDate(filterData?.dateRange?.startDateRange, "YYYY-MM-DD") : undefined,
      to: filterData?.dateRange?.endDateRange ? formatDate(filterData?.dateRange?.endDateRange, "YYYY-MM-DD") : undefined,
      type: attendedNotifications ? undefined : filterData?.notificationType,
      attended: attendedNotifications,
      patientTextSearch: filterData?.patientTextSearch,
      deviceSource: filterData?.deviceSource || undefined
    }
  })

  const notificationSummaryData: INotificationSummaryData =
    useGetNotificationsSummaryTotal({ role: userCurrent?.roleSlug });

  const filtersByTab = useMemo(() => {
    let roleFilters = ALLOWED_FILTERS_BY_ROLE_BY_TAB[userCurrent?.roleSlug];
    let filtersByTab: ALLOWED_FILTERS[] = [];
    if (roleFilters) {
      if ((roleFilters as any)[filterData?.notificationType || ""]) {
        filtersByTab = (roleFilters as any)[filterData?.notificationType || ""]
      } else {
        filtersByTab = (roleFilters as any)["DEFAULT"]
      }
    }
    return filtersByTab || [];
  }, [userCurrent?.roleSlug])

  const noficationsDashboardContext = useMemo(
    () => ({
      filterData, setFilterData, queryDateProps, notificationsData, notificationSummaryData, filtersByTab
    }),
    [
      filterData, setFilterData, queryDateProps, notificationsData, notificationSummaryData, filtersByTab
    ]
  )

  const showLoader = (
    notificationSummaryData.notificationsSummaryTotalIsLoading ||
    notificationsData.getNotificationsIsLoading ||
    !userCurrent
  );

  return (
    <NotificationsDashboardContext.Provider value={noficationsDashboardContext}>
      {
        showLoader ? <Loader /> : null
      }
      {children}
    </NotificationsDashboardContext.Provider>
  )
}