import { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes/routing'
import {
  Notification,
  NotificationType,
  useMarkNotificationAsRead,
} from 'features/Notifications/services'
import { useNotificationContext } from 'features/Notifications/hooks'
import Accordion from '../Accordion'
import {
  getHeaderDate,
  getAccordionTitle,
} from '../utils'
import {
  NotificationCardProps,
  NotificationGroupProps,
} from './types'
import useUser from 'features/shared/hooks/useUser'
import { ROLE } from 'global/constants/roles'
import { NotificationCardActions, NotificationCardContainer, NotificationCardDescription, NotificationCardHeader, ReadNotificationCardFooter, ReadNotificationCardHeader } from './NotificationCardNew.snippets'
import { elementsByRole } from './NotificationCard.const'
import { EventType } from 'global/constants/eventType'
import { replacePatientIdFromRoutes } from 'features/shared/utils/replacePatientId'

export function ViewNewCodeInstructions({
  notification
}: {
  notification: Notification
}) {
  const context = useNotificationContext();
  return (<span className='
    font-poppins
    font-medium
    text-left
    underline
    decoration-solid
    text-[#00B1CC]' style={{
      marginLeft: "0.3rem",
      cursor: "pointer"
    }}
    onClick={() => {
      context.setSelectedNotification(notification);
      context.setOpenDeviceReport(true);
    }}
  >Ver Instrucciones</span>)
}

export const NotificationCard: FC<NotificationCardProps> = ({
  notification,
  alert,
}) => {
  const { userCurrent } = useUser()
  const history = useHistory()
  const { setOpenVideoCall, setSelectedNotification, setOpenDeviceReport } =
    useNotificationContext()

  const onErrorMarkAsRead = () => {
    setSelectedNotification(null)
  }
  const onSettledMarkAsRead = () => {
    switch (notification.notificationType) {
      case 'call':
        if (!alert) {
          setOpenVideoCall(true)
        }
        break
      case 'medication':
        history.push(
          `${routes.clinicHistory.url}/patient/${notification.data.body.patientId}/daily-evolution?focusMessage=true`
        )
        break
    }
  }

  const { markNotificationAsRead } = useMarkNotificationAsRead({
    onErrorCb: onErrorMarkAsRead,
    onSettledCb: onSettledMarkAsRead,
  })

  const handleCallNotificationClick = () => {
    markNotificationAsRead(`${notification.notificationId}`)
  }

  const handleDeviceReportNotificationClick = () => {
    setOpenDeviceReport(true)
    setSelectedNotification(notification);
  }

  const handleMedicationNotificationClick = () => {
    markNotificationAsRead(`${notification.notificationId}`)
  }
  const handleDevicePrescriptionsNotificationClick = () => {
    markNotificationAsRead(`${notification.notificationId}`)
    history.push(routes.patientDashboard.url)
  }
  const handleDeviceRecommendationsNotificationClick = () => {
    markNotificationAsRead(`${notification.notificationId}`)
    history.push(routes.patientDashboard.url)
  }
  const handleDeviceConsultationsNotificationClick = () => {
    markNotificationAsRead(`${notification.notificationId}`)
    history.push(replacePatientIdFromRoutes(routes.patientWorkplan.url, `${notification.data.body.patientId}`))
  }
  const handleAnnotationsNotificationClick = () => {
    markNotificationAsRead(`${notification.notificationId}`)
    history.push(routes.patientDashboard.url)
  }
  const getAction = () => {
    switch (notification.notificationType) {
      case NotificationType.call:
        return handleCallNotificationClick
      case NotificationType.medication:
        return handleMedicationNotificationClick
      case NotificationType.report:
        return handleDeviceReportNotificationClick
      case NotificationType.prescription:
        return handleDevicePrescriptionsNotificationClick
      case NotificationType.recommendation:
        return handleDeviceRecommendationsNotificationClick
      case NotificationType.consultation:
        return handleDeviceConsultationsNotificationClick
      case NotificationType.annotations:
        return handleAnnotationsNotificationClick;
    }
  }

  const isRead = !!notification.readAt;
  let actionText: any = "";
  let actionClass = "";
  if (isRead) {
    let actionPerformed: string = "";
    const dateFormatted = getHeaderDate(notification.readAt || "");
    const dateFormattedDisplay = `${dateFormatted.match(/hace/i) ? `${dateFormatted.toLowerCase()}` : `el ${dateFormatted}`}`;

    actionClass = `font-poppins text-[12px] font-medium leading-4 tracking-[-0.2px] text-left decoration-skip-ink-none text-[_color_]`;
    let color = "";
    if (notification.notificationType === 'call') {
      actionPerformed = `Se devolvió la llamada `;
      color = "#48B16E";
    }
    if (notification.notificationType === NotificationType.report) {
      if (userCurrent?.roleSlug === ROLE.PATIENT) {
        actionPerformed = `Visto `;
        color = "#00B1CC";
      } else if (userCurrent?.roleSlug === ROLE.SERVICE_ADMIN) {
        actionPerformed = `Asignado `;
        color = "#407FE7";
      } else {
        actionPerformed = `Revisado `;
        color = "#407FE7";
      }
    }

    if (notification.notificationType === 'consultation') {
      actionPerformed = `Revisado `;
      color = "#407FE7";
    }
    actionClass = actionClass.replace("_color_", color);
    actionText = `${actionPerformed} ${dateFormattedDisplay}`;
  }

  const chosenRole = elementsByRole[userCurrent?.roleSlug] || null;
  const eventType = notification?.data?.body?.eventType || notification?.data?.body?.type || "";
  const chosenElement = chosenRole ? chosenRole[eventType] : null;
  let title = notification.data.title;
  if (chosenElement) {
    const relevantData = chosenElement(notification);
    actionClass = relevantData.actionClass;
    title = relevantData.title;
    actionText = relevantData.actionGenerated;
  }

  const alexaCodeGenerated = eventType === EventType.NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_CODE_GENERATED
  const hasSeenAlexaCodeGenerated = alexaCodeGenerated && isRead;

  return (
    <NotificationCardContainer>
      <NotificationCardHeader alert={alert} notification={notification} />
      <NotificationCardDescription>
        {title}
        {
          hasSeenAlexaCodeGenerated ? <ViewNewCodeInstructions notification={notification} /> : null
        }
      </NotificationCardDescription>
      {
        !isRead ? <NotificationCardActions
          variant={notification.notificationType}
          onClickAction={getAction()}
          notification={notification}
        /> : null
      }
      {
        isRead ? <div>
          <span className={actionClass}>{actionText}</span>
        </div> : null
      }
    </NotificationCardContainer>
  )
}

export const NotificationGroup: FC<NotificationGroupProps> = ({
  notificationsGroup,
  defaultOpen = false,
}) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Accordion
      key={notificationsGroup.date}
      title={getAccordionTitle(notificationsGroup.date)}
      defaultOpen={defaultOpen}
    >
      <div className={'transition duration-700 ease-in-out flex flex-row flex-wrap gap-x-2 gap-y-4'}>
        {
          !showMore ?
            (notificationsGroup?.notifications.slice(notificationsGroup?.notifications.length > 10 ? notificationsGroup?.notifications.length - 10 : 0).map((notification) => (
              <NotificationCard
                key={notification.notificationId}
                notification={notification}
              />
            )))
            : (notificationsGroup?.notifications.map((notification) => (
              <NotificationCard
                key={notification.notificationId}
                notification={notification}
              />
            )))

        }


      </div>
      {notificationsGroup?.notifications.length > 10 &&

        <div className="flex mt-10 w-full text-center justify-center	">
          {!showMore ?
            <button className="btn flex text-primary-main cursor-pointer font-semibold" onClick={() => setShowMore(!showMore)}>Ver más</button>
            : <button className="btn flex text-primary-main cursor-pointer font-semibold" onClick={() => setShowMore(!showMore)}>Ver menos</button>
          }
        </div>
      }
    </Accordion>
  )
}