import { useState } from "react"
import { NotificationCardActionsProps } from "./types"
import { calculatePotentialNotificationActionRoute } from "../utils"
import { variants } from "../NotificationsCounter/NotificationsCounterConst"
import { NotificationType, useMarkNotificationAsRead } from "features/Notifications/services"
import useUser from "features/shared/hooks/useUser"
import { useHistory, useLocation } from "react-router-dom"
import { ROLE } from "global/constants/roles"


export const NotificationCardPatientActionReleased = ({
  notification
}: {
  notification: NotificationCardActionsProps['notification'],
  selectedGroup?: NotificationType
}) => {
  const location = useLocation();
  const history = useHistory();
  const [handlingClick, setHandlingClick] = useState(false);
  const { userCurrent } = useUser();
  const { markNotificationAsRead } = useMarkNotificationAsRead()

  const handleClickAction = async () => {
    if (handlingClick) {
      return;
    }
    setHandlingClick(true);
    let finalRoute: string | null | undefined;
    if (userCurrent?.roleSlug === ROLE.HEALTH_PROFESSIONAL) {
      finalRoute = calculatePotentialNotificationActionRoute({
        notification,
        location: location as any
      })
    }
    if (finalRoute !== '/') {
      try {
        markNotificationAsRead(`${notification?.notificationId}`);
      } catch (e) {
        console.error({ e });
        setHandlingClick(false);
        return;
      }
    }
    setHandlingClick(false);
    if (finalRoute) {
      history.push(
        finalRoute
      )
    }
  }

  return (
    <div className='w-fit-content'>
      <div>
        <div
          className={`p-3 ${variants?.queryWorkplan?.active?.bg} rounded-lg min-h-[16px] cursor-pointer inline-block pl-4 pr-4`}
          onClick={handleClickAction}
        >
          <span className="flex flex-row items-center gap-2">
            <img
              src={variants?.queryWorkplan?.active?.iconName}
              alt={variants?.queryWorkplan?.active?.iconName}
            />
            <span className={'text-white text-sm text-caption'}>
              Ver Detalle
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}