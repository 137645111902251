import {
  format as formatDate,
  differenceInMinutes,
  formatDistanceToNow,
  isToday,
} from 'date-fns'
import { es } from 'date-fns/locale'
import { Notification, NotificationType } from '../services'
import { EventType } from 'global/constants/eventType'
import { ROLE } from 'global/constants/roles'
import { calculateActionRoutes } from 'features/shared/utils/calculateActionRoutes'
import { AVAILABLE_ACTIONS } from 'global/constants/enums'

export const getName = (name: string) => {
  return name.split(' ')[0]
}

export const getLastname = (name: string) => {
  const patientName = name.split(' ')
  if (patientName.length === 2) {
    return patientName[1]
  }
  if (patientName.length > 2) {
    return patientName[2]
  }
  return ''
}

export const getHeaderDate = (createdAt: string): string => {
  const notificationDate = new Date(createdAt)
  const diffInMin = differenceInMinutes(notificationDate, new Date())
  if (Math.abs(diffInMin) < 45) {
    const formattedDistance = formatDistanceToNow(notificationDate, {
      locale: es,
    })
    return 'Hace ' + formattedDistance.replace('minutos', 'min')
  }
  return formatDate(notificationDate, 'dd/MM/yyyy HH:mm a')
}

export const getFooterDate = (createdAt: string): string => {
  return formatDate(new Date(createdAt), 'dd/MM/yyyy HH:mm a')
}

export const getAccordionTitleDate = (date: string): string => {
  return formatDate(new Date(date + 'T00:00:00'), "EEEE dd 'de' MMMM", {
    locale: es,
  })
}

export const checkIsToday = (date: string): boolean => {
  return (isToday(new Date(date + 'T00:00:00')))
}

export const getDateFromQueryParam = (queryParamName: string, query: any) => {
  const date = query[queryParamName]
  return date ? new Date(date + 'T00:00:00') : undefined
}

export const getAccordionTitle = (date: string) => {
  if (checkIsToday(date)) {
    return 'Hoy'
  }
  return getAccordionTitleDate(date)
}

export const getAtHeaderDate = (dateAt: string) => {
  const dateFormatted = getHeaderDate(dateAt || "");
  return `${dateFormatted.match(/hace/i) ? `${dateFormatted.toLowerCase()}` : `el ${dateFormatted}`}`;
}

export const calculatePotentialNotificationActionRoute = ({
  notification,
  location,
  currentUserRoleSlug
}: {
  notification?: Notification,
  location: Location,
  currentUserRoleSlug?: string
}) => {
  const eventType = notification?.data?.body?.eventType || notification?.data?.body?.type || "";
  if (!notification) {
    return null;
  }

  const calculateReportActions = () => {
    const alexaPairingSuccess = eventType === EventType.NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_SUCCESSFUL;
    const alexaCodeGenerated = eventType === EventType.NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_CODE_GENERATED;
    const deviceAssigned = eventType === EventType.NOTIFICATION_TYPE_DEVICE_ASSIGNED;
    const isPatient = currentUserRoleSlug === ROLE.PATIENT;
    const patientId = notification?.data?.body?.patientId;

    if (deviceAssigned) {
      if (isPatient) {
        const finalRoute = calculateActionRoutes({
          action: AVAILABLE_ACTIONS.WORKPLAN_DEVICES,
          patientId: "",
          currentLocation: location
        })
        return finalRoute;
      }

      if (!patientId) {
        console.error("Patient ID not found on notification");
        return;
      }

      const finalRoute = calculateActionRoutes({
        action: AVAILABLE_ACTIONS.PATIENT_DETAILS_WORKPLAN,
        patientId: patientId.toString(),
        currentLocation: location
      })
      return finalRoute;
    } else {
      if (isPatient) {
        if (alexaCodeGenerated) {
          return null;
        }

        const finalRoute = calculateActionRoutes({
          action: AVAILABLE_ACTIONS.WORKPLAN_DEVICES,
          patientId: "",
          currentLocation: location
        })
        return finalRoute;
      } else {
        if (alexaPairingSuccess || alexaCodeGenerated) {
          let chosenAction: AVAILABLE_ACTIONS = AVAILABLE_ACTIONS.ACTIVE_PATIENTS;

          if (alexaPairingSuccess) {
            chosenAction = AVAILABLE_ACTIONS.PATIENT_DETAILS_WORKPLAN;
          }

          if (alexaCodeGenerated) {
            chosenAction = AVAILABLE_ACTIONS.PATIENT_DETAILS_WORKPLAN;
          }

          if (!patientId) {
            console.error("Patient ID not found on notification");
            return;
          }
          const finalRoute = calculateActionRoutes({
            action: chosenAction,
            patientId: patientId.toString(),
            currentLocation: location
          })
          return finalRoute;
        }
      }
    }
  }

  const calculatePrescriptionAction = () => {
    let chosenAction: AVAILABLE_ACTIONS = AVAILABLE_ACTIONS.WORKPLAN;
    const eventType = notification?.data?.body?.eventType || notification?.data?.body?.type || "";

    const matchedActions = {
      [EventType.NOTIFICATION_TYPE_PRESCRIPTION_DELETED]: AVAILABLE_ACTIONS.WORKPLAN_TREAMENT,
      [EventType.NOTIFICATION_TYPE_PRESCRIPTION_UPDATED]: AVAILABLE_ACTIONS.WORKPLAN_TREAMENT,
      [EventType.NOTIFICATION_TYPE_PRESCRIPTION_CREATED]: AVAILABLE_ACTIONS.WORKPLAN_TREAMENT,
      [EventType.NOTIFICATION_TYPE_MEDICAL_PERSONAL_ASSIGNED]: AVAILABLE_ACTIONS.WORKPLAN_MEDICAL_PERSONEL,
      [EventType.NOTIFICATION_TYPE_DEVICE_ASSIGNED]: AVAILABLE_ACTIONS.WORKPLAN_DEVICES,
      [EventType.NOTIFICATION_TYPE_RECOMMENDATION]: AVAILABLE_ACTIONS.WORKPLAN_RECOMMENDATIONS,
    }

    chosenAction = matchedActions[eventType] || AVAILABLE_ACTIONS.WORKPLAN_TREAMENT;
    const finalRoute = calculateActionRoutes({
      action: chosenAction,
      patientId: notification?.data?.body?.patientId?.toString() || "",
      currentLocation: location
    })
    return finalRoute;
  }

  const calculateNotificationCardActionRecommendation = () => {
    if (!notification?.data?.body?.patientId) {
      console.error("No patient ID provided...", notification);
    }
    const finalRoute = calculateActionRoutes({
      action: AVAILABLE_ACTIONS.WORKPLAN_RECOMMENDATIONS,
      patientId: notification?.data?.body?.patientId?.toString() as string,
      currentLocation: location
    });
    return finalRoute;
  }

  const calculateNotificationCardAnnotationAction = () => {
    let finalRoute: string | null = null;
    const patientId = notification?.data?.body?.patientId?.toString();
    if (currentUserRoleSlug === ROLE.PATIENT) {
      finalRoute = calculateActionRoutes({
        action: AVAILABLE_ACTIONS.WORKPLAN_ANNOTATIONS,
        patientId: "",
        currentLocation: location
      });
    } else {
      if (!patientId) {
        console.log("Patient ID not found on notification: cannot redirect to the patient details screen.");
        return null;
      }
      finalRoute = calculateActionRoutes({
        action: AVAILABLE_ACTIONS.PATIENT_DETAILS_NOTES,
        patientId: notification?.data?.body?.patientId?.toString() as string,
        currentLocation: location
      });
    }
    return finalRoute;
  }

  const calculatePatientEventAction = () => {
    if (!notification?.data?.body?.patientId) {
      console.error("No patient ID provided...", notification);
    }
    if (eventType === EventType.NOTIFICATION_TYPE_PATIENT_RELEASED) {
      const serviceId = parseInt(notification?.data?.body?.planId?.toString() || "INVALID_ID");
      if (serviceId && !isNaN(serviceId)) {
        return calculateActionRoutes({
          action: AVAILABLE_ACTIONS.ATTENTION_DETAILS,
          patientId: notification?.data?.body?.patientId?.toString() as string,
          currentLocation: location,
          serviceId
        });
      }
    }
    return null;
  }


  const simpleActionMap = {
    [NotificationType.call]: () => null,
    [NotificationType.report]: calculateReportActions,
    [NotificationType.prescription]: calculatePrescriptionAction,
    [NotificationType.recommendation]: calculateNotificationCardActionRecommendation,
    [NotificationType.patientEvents]: calculatePatientEventAction
  } as any;

  const chosenAction = simpleActionMap[notification.notificationType];
  if (chosenAction) {
    return chosenAction();
  }

  if (notification.notificationType === NotificationType.annotations || [
    EventType.NOTIFICATION_TYPE_NOTE_PATIENT,
    EventType.NOTIFICATION_TYPE_NOTE_DR_DAILY_EVOL
  ].includes(eventType || "")) {
    return calculateNotificationCardAnnotationAction();
  }
  //*/
}