import { MedicalDeviceServiceModel } from 'components/WorkPlan/hooks/useDevices';
import fetcher from 'features/shared/utils/fetcher'
import { useMutation } from 'react-query'

export interface DevicesReportedModel {
  patientName: string;
  specialistName: string;
  serviceId: number;
  deviceName: string;
  deviceCode: string;
  serial: string;
  planDeviceId: number;
  reportType: number;
  comment: string;
  reviewed: number;
  createdAt: string;
  updatedAt: string;
  planDeviceData?: MedicalDeviceServiceModel
}

export function useUpdateDeviceStatus() {
  return useMutation((planDeviceId: any) => updateDeviceStatus(planDeviceId));
}

export async function updateDeviceStatus(planDeviceId: any) {
  return await fetcher.put(`/alerts/devices/${planDeviceId}/review`);
}

export function useGetDevicesReported() {
  return useMutation((query: string) => getDeviceStatus(query));
}

export async function getDeviceStatus(query: string) {
/*   console.log('holaa query', query)
 */  
return await fetcher.get('/alerts/devices'+ query);
}