import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { MedicalDeviceServiceModel, RegisterMedicalDeviceModel, ReportMedicalDeviceModel } from '../hooks/useDevices';
import { useIsMutating } from "react-query";
import useUser from 'features/shared/hooks/useUser';
import { MedicalDeviceHeader } from './MedicalDeviceCard.header';
import { MedicalDeviceCardBody } from './MedicalDeviceCard.body';
import { MedicalDeviceCardLoader } from './MedicalDeviceCard.body.loader';

interface MedicalDeviceCardProps {
  data: MedicalDeviceServiceModel;
  onActivate: (body: RegisterMedicalDeviceModel, planDeviceId: number) => any;
  onReplace: (body: RegisterMedicalDeviceModel) => any;
  onReportDevice: (body: ReportMedicalDeviceModel, device: MedicalDeviceServiceModel) => any;
  serviceId?: number;
  onRemove: (value: any) => void;
  onReRequestDevicesData: () => void;
}

const MedicalDeviceCard: React.FC<MedicalDeviceCardProps> = ({
  data,
  onActivate,
  onReplace,
  onReportDevice,
  serviceId,
  onRemove,
  onReRequestDevicesData
}) => {
  const { userCurrent } = useUser()
  const { register, watch, setValue } = useForm();
  const isMutating = useIsMutating({ mutationKey: [serviceId, data.deviceId] });
  useEffect(() => {
    setValue('serial', data.serial);
  }, [data.serial]);
  useEffect(() => {
    setValue('planDevicePairing', data.planDevicePairing);
  }, [data.planDevicePairing]);

  return (
    <div
      data-testid={data.deviceName}
      className={`
        border-solid border-[1px] border-grey-200 rounded-[16px] p-4
        ${data.status === 2 ? 'border-orange-400' : ''}
      `}
    >
      {
        (isMutating > 0) ?
          <MedicalDeviceCardLoader />
          :
          <>
            <MedicalDeviceHeader
              data={data}
              onActivate={onActivate}
              userCurrent={userCurrent}
              onReplace={onReplace}
              watch={watch}
              setValue={setValue}
              onReportDevice={onReportDevice}
              onRemove={onRemove}
              onReRequestDevicesData={onReRequestDevicesData}
            />
            <MedicalDeviceCardBody
              watch={watch}
              register={register}
              data={data}
            />
          </>
      }
    </div>
  )
}

export default MedicalDeviceCard;
