import React, { useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import Moment from 'moment'
import ScrollToBottom from 'react-scroll-to-bottom'

import useUser from 'features/shared/hooks/useUser'
import { useQueryParams } from 'features/shared/hooks/use-query-params'
import {
  useCreateAnnotation,
  useGetAnnotations,
} from 'features/ClinicHistory/hooks/useAnnotation'
import { AnnotationModel } from 'features/ClinicHistory/hooks/annotation'

import LabelForm from 'components/LabelForm'
import Input from 'components/input'
import Avatar from 'components/Avatar'
import AnnotationsCard from 'components/AnnotationsCard'
import ButtonToggle from 'components/ButtonToggle'

import IconSend from 'assets/icons/icon_send.svg'
import IconFile from 'assets/icons/icon_file.svg'
import { onAlert } from 'components/Alert'
import { ROLE } from 'global/constants/roles'
import { EventType } from 'global/constants/eventType'
import { useCreateGeneralNotification } from 'features/ClinicHistory/hooks/useNotification'

interface Props {
  className?: string
  planId?: number
  patientId?: number
  buttonToggle?: boolean
  buttonToggleName?: string
  buttonToggleIcon?: any
  buttonToggleFuntion?: () => void
  patientView: boolean
}

const AnnotationMedical: React.FC<Props> = ({
  planId,
  className,
  patientId,
  patientView,
  buttonToggle,
  buttonToggleIcon,
  buttonToggleName,
  buttonToggleFuntion,
}) => {
  const { query } = useQueryParams()
  const inputMessageRef = useRef<HTMLInputElement>(null)
  const queryClient = useQueryClient()
  const { data } = useGetAnnotations(planId)
  const { mutate } = useCreateAnnotation()
  const { userCurrent } = useUser()
  const annotations = data?.data
  const [message, setMessage] = useState('')

  const { mutateAsync: createCustomNotification } =
    useCreateGeneralNotification()

  async function handleNotifyPatientOfNewDoctorRecommendation({
    noteId
  }: {
    noteId: number
  }) {
    try {
      await createCustomNotification({
        json: {
          patientId,
          noteId
        },
        fromRole: ROLE.HEALTH_PROFESSIONAL,
        toRole: ROLE.PATIENT,
        type: EventType.NOTIFICATION_TYPE_NOTE_PATIENT
      })
    } catch (e) {
      console.log({
        e
      });
    }
  }

  const handleConversations = () => {
    if (message) {
      mutate(
        {
          planId,
          userId: userCurrent?.id,
          message,
        },
        {
          onSuccess: (response) => {
            if (response?.data) {
              queryClient.invalidateQueries('annotations')
              console.log({
                response
              });
              handleNotifyPatientOfNewDoctorRecommendation({
                noteId: response.data.id
              });
            } else {
              onAlert.error(response);
            }
          },
        }
      )
      setMessage('')
    }
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleConversations()
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (query['focusMessage'] && inputMessageRef.current) {
        inputMessageRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [query])

  return (
    <div className={className}>
      <div className="bg-white shadow-md border-solid border-[1px] border-grey-200 rounded-[16px] p-[16px] row-span-1 col-span-full">
        <div className="flex justify-between items-center mb-4">
          <LabelForm
            icon={IconFile}
            label="Anotaciones del paciente"
            className="text-[14px] leading-[20px] font-medium"
          />
          {buttonToggle && (
            <ButtonToggle
              icon={buttonToggleIcon}
              onClick={buttonToggleFuntion}
              isActive={false}
              className="h-[40px] rounded-[8px] bg-primary-main  "
            >
              <span className="text-white">{buttonToggleName}</span>
            </ButtonToggle>
          )}
        </div>

        <ScrollToBottom
          followButtonClassName="ScrollToBottom__btn"
          scrollViewClassName=" overflow-y-scroll scrollbar"
          className=" bg-white rounded-lg  w-full h-[360px] "
        >
          <div className=" pr-[16px]">
            {annotations?.map((conversation: AnnotationModel, i: number) => (
              <React.Fragment key={i}>
                <Avatar
                  name={conversation?.name}
                  role={conversation?.speciality}
                  image={conversation?.image}
                  className={`${(
                    patientView
                      ? conversation?.userId !== patientId
                      : conversation?.userId === patientId
                  )
                    ? 'mb-2 flex-row-reverse justify-end'
                    : 'mb-2 justify-end'
                    }`}
                />
                <AnnotationsCard
                  commentary={
                    conversation &&
                    `Comentario (${Moment(conversation?.createdAt).format(
                      'DD/MM/YYYY, HH:mm:ss'
                    )})`
                  }
                  description={conversation?.message}
                  className={`${conversation?.userId === patientId
                    ? 'bg-[#F6F6F6] mb-2'
                    : 'bg-[#DEF1FF] mb-2'
                    }  `}
                />
              </React.Fragment>
            ))}
          </div>
        </ScrollToBottom>
        <Input
          autoFocus={query['focusMessage'] === 'true'}
          ref={inputMessageRef}
          label="Mensaje"
          icon={IconSend}
          value={message}
          send={true}
          sendValidate={message ? true : false}
          onChange={(event) => setMessage(event.target.value)}
          onKeyDown={handleKeyDown}
          onClickIcon={() => {
            handleConversations()
          }}
        />
      </div>
    </div>
  )
}

export default AnnotationMedical
