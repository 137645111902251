import Button from "components/Button"
import VerticalMenu from "components/VerticalMenu"
import IconErrorTriangle from 'assets/icons/icon_error_triangle.svg';
import { ROLE } from "global/constants/roles";
import { MedicalDeviceServiceModel, RegisterMedicalDeviceModel, ReportMedicalDeviceModel } from "../hooks/useDevices";
import Replace from "./Replace";
import { Dispatch, Fragment, SetStateAction, useCallback, useMemo, useState } from "react";
import Report from "./Report";
import { PLAN_DEVICE_ACTIVE, PLAN_DEVICE_REPORTED } from "global/constants/devices";
import useUser from "features/shared/hooks/useUser";
import { useRequestPairingCodeGeneration } from "features/Devices/hooks/useGeneratePairingCode";
import { RemoveDevice } from "./Remove";
import IconTrash from 'assets/icons/trash_grey.svg';
import { useCreateGeneralNotification } from "features/ClinicHistory/hooks/useNotification";
import { EventType } from "global/constants/eventType";
import { useParams } from "react-router-dom";

export const RenderActions = ({
  data,
  setShowReplace,
  onActivate,
  watch,
  setValue,
  onReRequestDevicesData
}: {
  data: MedicalDeviceServiceModel,
  setShowReplace: Dispatch<SetStateAction<boolean>>,
  onActivate: (body: RegisterMedicalDeviceModel, planDeviceId: number) => any;
  watch: any;
  setValue: any;
  onReRequestDevicesData: () => void
}) => {
  const { userCurrent } = useUser();
  const { mutateAsync: attemptGeneratePairingCode, isLoading: isGeneratingPairingCode } =
    useRequestPairingCodeGeneration()
  const { mutateAsync: createCustomNotification, isLoading: isSendingOTPCodeGeneratedNotification } =
    useCreateGeneralNotification()
  const isLoadingOTPGeneration = isSendingOTPCodeGeneratedNotification || isGeneratingPairingCode;
  const params = useParams<{
    patientId?: string
    documentPatient?: string
  }>();

  const notifyOTPCodeGenerated = useCallback(async ({
    otp
  }: {
    otp: string
  }) => {
    const patientId = params.patientId || params.documentPatient;
    if (isLoadingOTPGeneration) {
      return;
    }

    if (userCurrent?.roleSlug === ROLE.HEALTH_PROFESSIONAL) {
      //From health professional
      await Promise.allSettled([
        createCustomNotification({
          json: {
            pairingCode: otp,
            patientId
          },
          fromRole: ROLE.HEALTH_PROFESSIONAL,
          toRole: ROLE.PATIENT,
          type: EventType.NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_CODE_GENERATED
        }),
      ])
    }

    //From service admin
    if (userCurrent?.roleSlug === ROLE.SERVICE_ADMIN) {
      await Promise.allSettled([
        createCustomNotification({
          //We notify the patient assigned to this device
          json: {
            pairingCode: otp,
            patientId
          },
          fromRole: ROLE.SERVICE_ADMIN,
          toRole: ROLE.PATIENT,
          type: EventType.NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_CODE_GENERATED
        }),
      ])
    }
  }, [params.patientId, params.documentPatient, userCurrent]);

  const attemptGenerateCode = async () => {
    if (isLoadingOTPGeneration) {
      return;
    }
    try {
      const { data: resultingData } = await attemptGeneratePairingCode({ planDeviceId: `${data.id}` })
      await notifyOTPCodeGenerated({
        otp: resultingData.otp.code
      })
      setValue('planDevicePairing', resultingData);
      onReRequestDevicesData();
    } catch (err) {
      console.log({ err });
    }
  }

  if (data.status === PLAN_DEVICE_REPORTED) {
    return <Button
      className='mr-1 border-none focus:none bg-orange-400 !h-[32px] !px-[8px] !rounded-[8px] !text-body1'
      variant='primary'
      size="ultra-tiny"
      onClick={() => setShowReplace(true)}
    >
      Reemplazar
    </Button>
  }

  if (data.devicePairing?.otp_generation) {
    if (data.devicePairing?.otp_generation?.allowed_generation_role?.includes(userCurrent?.roleSlug)) {
      const paired = !!data.planDevicePairing?.pairing?.pairedWith;
      const isActive = data.status === PLAN_DEVICE_ACTIVE;
      const disabled = isLoadingOTPGeneration || paired || isActive;
      return <Button
        className='mr-1 !h-[32px] !px-[8px] !rounded-[8px] !text-body1'
        variant='primary'
        size="ultra-tiny"
        onClick={attemptGenerateCode}
        disabled={disabled}
      >{isLoadingOTPGeneration ? 'Generando...' : 'Generar Código'}</Button>;
    }
  }

  return <Button
    className='mr-1 !h-[32px] !px-[8px] !rounded-[8px] !text-body1'
    variant='primary'
    size="ultra-tiny"
    onClick={() => onActivate({
      deviceId: data.deviceId,
      serial: watch('serial'),
    }, data.id)}
    disabled={data.status > 0 ? true : !watch('serial')}
  >Activar</Button>
}

export const MedicalDeviceHeader = ({
  data,
  onActivate,
  userCurrent,
  onReplace,
  watch,
  setValue,
  onReportDevice,
  onRemove,
  onReRequestDevicesData
}: {
  data: MedicalDeviceServiceModel,
  onActivate: (body: RegisterMedicalDeviceModel, planDeviceId: number) => any;
  userCurrent: any;
  onReplace: (body: RegisterMedicalDeviceModel) => any;
  watch: any,
  setValue: any
  onReportDevice: (body: ReportMedicalDeviceModel, device: MedicalDeviceServiceModel) => any;
  onRemove: (planDeviceElement: any) => void
  onReRequestDevicesData: () => void
}) => {
  const [showReplace, setShowReplace] = useState<boolean>(false);
  const [showReport, setShowReport] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState(false);

  const actionItems = useMemo(() => {
    const calculatedItems = [];
    if (data.status === PLAN_DEVICE_ACTIVE && userCurrent?.roleSlug !== ROLE.SERVICE_ADMIN) {
      calculatedItems.push(
        <li className={`cursor-pointer py-4 hover:bg-grey-200 text-caption w-[133px] `} >
          <div onClick={() => setShowReport(true)} className='flex mx-4'>
            <img className='w-[18px] h-[18px] mr-4' src={IconErrorTriangle} alt="report" /> <span>Reportar</span>
          </div>
        </li>)
    }

    if (data.status === PLAN_DEVICE_ACTIVE
      || (data.planDevicePairing?.otp?.code)
    ) {
      calculatedItems.push(
        <li className={`cursor-pointer py-4 hover:bg-grey-200 text-caption w-[133px] `} onClick={() => setShowDelete(true)}>
          <div className='flex mx-4'>
            <img className='w-[18px] h-[18px] mr-4' src={IconTrash} alt="report" style={{
              color: "black"
            }} /> <span>Eliminar</span>
          </div>
        </li>
      );
    }

    return calculatedItems;
  }, [data, userCurrent])

  return (<div className='flex items-start'>
    <p className='text-headline5 font-semibold grow'>{data.deviceName}</p>
    <RenderActions
      data={data}
      setShowReplace={setShowReplace}
      onActivate={onActivate}
      watch={watch}
      setValue={setValue}
      onReRequestDevicesData={onReRequestDevicesData}
    />
    {
      actionItems.length ? <VerticalMenu>
        {actionItems.map((item, index) => <Fragment key={index}>
          {item}
        </Fragment>)}
      </VerticalMenu> : null
    }

    <Replace
      showModal={showReplace}
      onCloseModal={() => { setShowReplace(false) }}
      onSubmitForm={(serial) => {
        onReplace({
          deviceId: data.deviceId,
          serial,
        });
        setValue('serial', serial);

      }}
    />
    <Report
      showModal={showReport}
      onCloseModal={() => { setShowReport(false) }}
      onSubmitForm={(report) => {
        onReportDevice(report, data);
      }}
    />
    <RemoveDevice
      showModal={showDelete}
      onCloseModal={() => { setShowDelete(false) }}
      onRemove={onRemove}
      deviceId={`${data.deviceId}`}
    />
  </div>)
}