import { MeetingVideoCall, MeetingVideoCallProps } from "components/MeetingVideoCall";
import { createContext, Dispatch, SetStateAction, useState } from "react";
import IconClose from 'assets/icons/icon_close_white.svg'

export interface ICallContext {
  currentMeeting?: MeetingVideoCallProps,
  setCurrentMeeting: Dispatch<SetStateAction<MeetingVideoCallProps | undefined>>
}

export const CallContext = createContext<ICallContext>({
  setCurrentMeeting: () => { }
})

export const CallContextProvider = ({
  children
}: {
  children: any
}) => {
  const [currentMeeting, setCurrentMeeting] = useState<MeetingVideoCallProps | undefined>();

  const value = {
    currentMeeting,
    setCurrentMeeting
  };

  return (
    <CallContext.Provider value={value}>
      {
        currentMeeting ?
          <div className="fixed w-screen h-screen z-[10000] left-0 top-0 bg-black/40 flex justify-center items-center">
            <span className="absolute right-0 top-0 p-8 cursor-pointer" onClick={() => {
              setCurrentMeeting(undefined)
            }}>
              <img src={IconClose} alt={IconClose} style={{
                width: "2em",
                height: "3em"
              }} />
            </span>
            <MeetingVideoCall
              planId={currentMeeting.planId}
              userId={currentMeeting.userId}
              userProfile={currentMeeting.userProfile}
              getIsClose={currentMeeting.getIsClose}
              initCall={currentMeeting.initCall}
            />
          </div> : null
      }
      {children}
    </CallContext.Provider>
  )
}