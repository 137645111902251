import { useMutation } from "react-query";
import fetcher from 'features/shared/utils/fetcher'

interface IGeneratePairingCodeRequest {
  planDeviceId: string;
}

export function useRequestPairingCodeGeneration() {
  return useMutation((data: IGeneratePairingCodeRequest) => requestPairingCode(data));
}

export async function requestPairingCode({ planDeviceId }: IGeneratePairingCodeRequest) {
  return await fetcher.post(`/devices-pairing/generate`, {
    planDeviceId
  });
}