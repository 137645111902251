import { FC } from 'react'

import { routes } from 'routes/routing'
import { getName, getLastname, getHeaderDate } from '../utils'

import { NotificationsModalProps, NotificationModalCardProps } from './types'
import { useHandleNotificationAction } from './useHandleNotificationAction'

export const NotificationModalCard: FC<NotificationModalCardProps> = ({
  notification,
}) => {
  const { handleNotificationAction } = useHandleNotificationAction(notification);
  const name = getName(notification.name)
  const lastname = getLastname(notification.name)
  const date = getHeaderDate(notification.createdAt)
  return (
    <div
      className={
        'flex flex-row justify-between w-full border-b-[1px] border-[#F6F6F6] hover:bg-gray-200 cursor-pointer min-h-[124px] p-4'
      }
      onClick={handleNotificationAction}
    >
      <div className={'flex flex-row gap-1 w-full'}>
        <div
          className={`text-[#008EA3] flex justify-center items-baseline rounded-full text-base font-medium`}
        >
          <span className='bg-[#DFF5FF] w-10 h-10 rounded-full text-base font-medium flex justify-center items-center'>
            {name.charAt(0)}
            {lastname.charAt(0)}
          </span>
        </div>
        <div className={'flex flex-col pl-3 flex-1'}>
          <span className={'text-body2'}>
            {name} {lastname}
          </span>
          <span className={'font-poppins text-sm font-normal leading-5 tracking-wide text-left text-[#585A5B] pt-3'}>
            {notification.data.title}
          </span>
        </div>
        <div className='-mt-0.5'>
          <span className={'text-caption whitespace-nowrap text-[#585A5B]'}>{date}</span>
        </div>
      </div>
    </div >
  )
}

export const NotificationsModal: FC<NotificationsModalProps> = ({
  totalNotifications = 0,
  notifications = [],
}) => {
  return (
    <div
      className={`left-[-41vw] w-[330px] sm:left-[inherit] sm:right-[-10px] 
        sm:w-[488px] top-[32px] flex flex-col overflow-x-hidden 
        overflow-y-auto z-50 outline-none focus:outline-none absolute 
        shadow-2xl rounded-2xl bg-white box-border`}
    >
      <div className="flex flex-col w-full">
        <div className="w-full outline-none focus:outline-none overflow-hidden">
          <div className="border-b-[1px] border-[#F6F6F6] flex justify-between items-center p-4 mb-0">
            <span className="text-left text-Fs8 font-medium" style={{
              color: "#141414"
            }}>
              Notificaciones
            </span>
            {notifications.length > 0 ? (
              <div
                className={
                  'text-caption text-[#008EA3] bg-[#E2FBFF] rounded-2xl py-1 px-3 px-4'
                }
              >{`${totalNotifications} nuevos`}</div>
            ) : null}
          </div>
          <div className={''}>
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <NotificationModalCard
                  key={notification.notificationId}
                  notification={notification}
                />
              ))
            ) : (
              <div className={'flex flex-row justify-center'}>
                <span className={'text-body1 text-[#545454]'}>
                  Aún no tienes notificaciones
                </span>
              </div>
            )}
          </div>
          <div className={'w-full p-4'}>
            {notifications.length > 0 ? (
              <a href={routes.notifications.url}>
                <button className="w-full text-Fs8 text-white text-body1 py-4 bg-[#008EA3] rounded-3xl h-[48px] flex justify-center items-center cursor-pointer">
                  <span className='font-poppins text-lg font-semibold leading-6 tracking-wider text-center'>
                    Ver notificaciones
                  </span>
                </button>
              </a>
            ) : (
              <button className="w-full text-Fs8 text-[#ADADAD] text-body1 py-4 bg-[#F6F6F6] rounded-3xl h-[48px] flex justify-center items-center cursor-not-allowed">
                <span className='font-poppins text-lg font-semibold leading-6 tracking-wider text-center'>
                  Ver notificaciones
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

