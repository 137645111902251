import React from "react";
import IconClose from 'assets/icons/icon_close.svg';
import Button from "components/Button";
import { useDeleteDeviceService } from "../hooks/useDevices";

interface Props {
  showModal: boolean;
  onCloseModal: () => any;
  deviceId: string
  onRemove: (value: any) => void;
}

export const RemoveDevice: React.FC<Props> = ({
  showModal,
  onCloseModal,
  deviceId,
  onRemove
}) => {
  const { isLoading: isLoadingDeleteDeviceService } = useDeleteDeviceService();

  const deleteDevice = async () => {
    try {
      onRemove({
        value: parseInt(deviceId)
      })
      onCloseModal();
    } catch (e) {
      console.log({
        e
      });
    }
  }

  if (showModal) {
    return (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-full max-w-[388px]">
            <div
              className="border-0 rounded-lg shadow-lg relative w-full  bg-white outline-none focus:outline-none overflow-hidden p-4"
            >
              <div className='border-b-[1px] border-[#14141426] pb-[24px] flex justify-between items-center mb-4'>
                <span className='text-left text-Fs8 font-medium'>Eliminar Dispositivo</span>
                <button
                  onClick={onCloseModal}
                  className="h-[30px] w-[30px] flex items-center justify-center"
                  disabled={isLoadingDeleteDeviceService}
                >
                  <img src={IconClose} alt="close" className='min-w-[1.5rem] min-h-[1.5rem] w-6 h-6' />
                </button>
              </div>

              <p className="mb-4" style={{
                fontSize: "14px"
              }}>
                ¿Estás seguro de querer eliminar el dispositivo asignado? Esta acción no se puede deshacer.
              </p>
              <div className="flex justify-end w-full">
                <Button
                  onClick={onCloseModal}
                  variant='secondary'
                  size="small"
                  disabled={isLoadingDeleteDeviceService}
                >Cancelar </Button>
                <Button
                  onClick={deleteDevice}
                  variant='primary'
                  size="small"
                  style={{
                    marginLeft: '8px'
                  }}
                  disabled={isLoadingDeleteDeviceService}
                >Eliminar </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  } else {
    return <></>
  }
};
