import React, { useMemo } from 'react'
import IconClose from 'assets/icons/icon_close.svg'
import Button from 'components/Button'
import Chip from 'components/Chip'
import Avatar from 'components/Avatar'
import TextArea from 'components/TextArea'
import { markNotificationAsRead, Notification } from '../services'
import { EventType } from 'global/constants/eventType'
import { AlexaCodeGeneratedPairingInstructions } from './DeviceNotification'
import { useNotificationContext } from '../hooks'
import { DevicesReportedModel } from 'features/Devices/hooks/useDevice'

interface ReportDeviceProps {
  onCloseModal: () => any
  onSubmitForm?: () => any
  deviceSelected?: DevicesReportedModel
  selectedNotification?: Notification
}

const ReportDevice: React.FC<ReportDeviceProps> = (props) => {
  const OTP = props.deviceSelected?.planDeviceData?.pairing?.otp?.code;
  const context = useNotificationContext();
  const eventType = props.selectedNotification?.data?.body?.type || props.selectedNotification?.data?.body?.eventType;
  const alexaCodeGenerated = eventType === EventType.NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_CODE_GENERATED;

  if (alexaCodeGenerated) {
    return <div>
      {
        (props.selectedNotification && props.selectedNotification.data?.body?.device) ? <AlexaCodeGeneratedPairingInstructions
          onCloseModal={() => {
            context.setOpenDeviceReport(false);
          }}
          deviceSelected={props.selectedNotification.data.body.device}
          onSubmitForm={async () => {
            if (props.selectedNotification) {
              console.log({
                "props.selectedNotification": props.selectedNotification
              });
            }
            if (!props.selectedNotification?.readAt) {
              await markNotificationAsRead(`${props.selectedNotification?.notificationId}`)
            }
            context.setOpenDeviceReport(false);
          }}
        /> : null
      }
    </div>
  }

  const patientCharName = useMemo(() => {
    const char1 = (props.deviceSelected?.patientName || "").charAt(0);
    const char2 = (props.deviceSelected?.patientName || "").split("\s")?.pop()?.charAt(0);
    return `${char1 || ""}${char2 || ""}`
  }, [props.deviceSelected?.patientName])

  const specialistCharName = useMemo(() => {
    const char1 = (props.deviceSelected?.specialistName || "").charAt(0);
    const char2 = (props.deviceSelected?.specialistName || "").split("\s")?.pop()?.charAt(0);
    return `${char1 || ""}${char2 || ""}`
  }, [props.deviceSelected?.specialistName])

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full max-w-[436px]">
          <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none overflow-hidden p-4">
            <div className="border-b-[1px] border-[#14141426] pb-[24px] flex justify-between items-start mb-4 w-full">
              <div className="flex items-center gap-4 w-full">
                <div className='flex-1 w-[50%]'>
                  <span className="text-subtitle1 break-words">
                    {props.deviceSelected?.deviceName}
                  </span>
                  <br />
                  <span className="text-caption h-4 font-poppins font-normal text-[12px] tracking-[-0.2px] text-[#585A5B] break-words">
                    COD: {props.deviceSelected?.serial || OTP}
                  </span>
                </div>
                <div className='-mt-5 flex-1 flex justify-end'>
                  {props.deviceSelected?.reviewed === 0 ? (
                    <Chip
                      label="Reportado"
                      onClose={() => { }}
                      isRemovable={false}
                      className="bg-[#FFEFED] text-[#E21010] text-[12px] font-medium decoration-skip-ink-none w-fit"
                    />
                  ) : (
                    <Chip
                      label="Revisado"
                      onClose={() => { }}
                      isRemovable={false}
                      className='bg-cyan-50 text-[12px] font-medium decoration-skip-ink-none w-fit'
                    />
                  )}
                </div>
              </div>
              <button
                onClick={() => props.onCloseModal()}
                className="h-[30px] w-[30px] flex items-center justify-center"
              >
                <img src={IconClose} alt="close" className="min-w-[1.5rem] min-h-[1.5rem] w-6 h-6" />
              </button>
            </div>

            <div className="mb-4">
              <p className="text-body2 pb-2 font-poppins text-[14px] font-medium leading-6 tracking-[0.2px] text-left decoration-skip-ink-none">Paciente:</p>
              <Avatar name={props.deviceSelected?.patientName} className={'font-poppins text-[14px] font-medium leading-6 tracking-[0.2px] text-left decoration-skip-ink-none'}
                nameClassName='font-poppins text-sm font-medium leading-6 tracking-wide text-left'
                circleText={patientCharName}
                circleClassname='font-poppins text-base font-medium leading-6 tracking-wide text-center'
              />
            </div>
            <div>
              <p className="text-body2 pb-2 font-poppins text-[14px] font-medium leading-6 tracking-[0.2px] text-left decoration-skip-ink-none">Reportado por:</p>
              <Avatar
                name={props.deviceSelected?.specialistName}
                role="Medico General"
                className={'font-poppins text-[14px] font-medium leading-6 tracking-[0.2px] text-left decoration-skip-ink-none'}
                nameClassName='font-poppins text-sm font-medium leading-6 tracking-wide text-left'
                circleText={specialistCharName}
                circleClassname='font-poppins text-base font-medium leading-6 tracking-wide text-center'
              />
            </div>

            <div className="mt-6">
              <TextArea
                label="Comentario"
                value={props.deviceSelected?.comment}
                readOnly
                className={'font-poppins text-sm font-normal leading-4 tracking-tight text-left'}
              />
            </div>

            <div className="flex items-center justify-center w-full mt-6">
              <Button
                onClick={() => {
                  props.onSubmitForm?.()
                  props.onCloseModal()
                }}
                variant="primary"
                size="large"
                type="button"
                disabled={
                  props.deviceSelected?.reviewed !== 0 ||
                  props.onSubmitForm === undefined
                }
                className='font-poppins text-base font-semibold text-center w-full'
              >
                Revisar Dispositivo
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default ReportDevice

