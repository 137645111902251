import { NotificationCardActionsProps, NotificationCardHeaderProps, ReadNotificationCardFooterProps, ReadNotificationCardHeaderProps } from "./types"
import { calculatePotentialNotificationActionRoute, getFooterDate, getHeaderDate, getLastname, getName } from "../utils"
import { EventType } from "global/constants/eventType"
import { variants } from "../NotificationsCounter/NotificationsCounterConst"
import { markNotificationAsRead, NotificationType, PatientPlanAssignedPersonnel, useMarkNotificationAsRead } from "features/Notifications/services"
import useUser from "features/shared/hooks/useUser"
import { useHistory, useLocation } from "react-router-dom"
import { ROLE } from "global/constants/roles"
import { useNotificationContext } from "features/Notifications/hooks"


export const NotificationCardActionReport = ({
    notification,
    selectedGroup
}: {
    notification: NotificationCardActionsProps['notification'],
    selectedGroup?: NotificationType
}) => {
    const eventType = notification?.data?.body?.eventType || notification?.data?.body?.type;
    const alexaPairingError = eventType === EventType.NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_FAILED;
    const alexaPairingCodeGenerated = eventType === EventType.NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_CODE_GENERATED;
    const isReport = eventType === EventType.NOTIFICATION_TYPE_REPORT_DEVICE;
    const location = useLocation();
    const { userCurrent } = useUser();
    const history = useHistory();
    const notificationContext = useNotificationContext();

    const handleClickAction = async () => {
        let newRoute = calculatePotentialNotificationActionRoute({
            notification,
            location: location as any,
            currentUserRoleSlug: userCurrent?.roleSlug
        })
        if (newRoute) {
            markNotificationAsRead(`${notification?.notificationId}`)
            history.push(
                newRoute
            )
            await markNotificationAsRead(`${notification?.notificationId}`)
        } else if (isReport) {
            if (notification) {
                notificationContext.setSelectedNotification(notification);
                notificationContext.setOpenDeviceReport(true);
            }
        } else if (alexaPairingError) {
            console.log("Handling report modal display... TODO");
        } else if (alexaPairingCodeGenerated && notification) {
            notificationContext.setSelectedNotification(notification);
            notificationContext.setOpenDeviceReport(true);
        } else {
            console.log("Action hasn't been handled...", {
                notification
            });
        }
    }
    let label = "Revisar";

    let selectedVariant = (variants as any)[selectedGroup as any] || (variants as any)[notification?.notificationType as any] || null;
    if (
        ((notification?.notificationType === NotificationType.report) && userCurrent?.roleSlug === ROLE.PATIENT)
        || ((notification?.notificationType === NotificationType.report) && userCurrent?.roleSlug === ROLE.HEALTH_PROFESSIONAL)
        || (selectedGroup === NotificationType.queryWorkplan)
    ) {
        selectedVariant = variants.queryWorkplan;
        label = "Ver Detalle"
    }
    if (!selectedVariant) {
        selectedVariant = variants.report;
    }
    const bg = selectedVariant.active?.bg;
    const icon = selectedVariant.active?.iconName;

    return (
        <div className='w-fit-content'>
            <div>
                <div
                    className={`p-3 ${bg} rounded-lg min-h-[16px] cursor-pointer inline-block pl-4 pr-4`}
                    onClick={handleClickAction}
                >
                    <span className="flex flex-row items-center gap-2 pr-4">
                        <img
                            src={icon}
                            alt={icon}
                        />
                        <span className={'text-white text-sm text-caption'}>
                            {label}
                        </span>
                    </span>
                </div>
            </div>
        </div>
    )
}